import { cloneElement } from 'react';
import classNames from 'classnames';
import { HeadingSmall } from 'src/design/components/heading/Headings';
import { transactionFeeText } from 'src/settings/text';
import BodyText from 'src/design/components/bodyText/BodyText';
import ColoredAngularBackgroundSection from 'src/components/landingPages/common/coloredAngularBackgroundSection/ColoredAngularBackgroundSection';
import GetStartedButton from 'src/components/landingPages/common/getStartedButton/GetStartedButton';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import Tabs from 'src/design/components/tabs/Tabs';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import homeStyles from 'src/components/landingPages/home/Home.mscss';
import styles from 'src/components/landingPages/common/twoWaysToInvest/TwoWaysToInvest.mscss';

const SmartInvestPanel = ({
  className,
  primaryCta = <GetStartedButton testRef="si-tab-primary-cta" />,
  secondaryCta,
  extraInfo,
}) => (
  <div
    className={classNames(
      className,
      styles.smartInvestPanel,
      styles.twoWaysTabPanel
    )}
    data-test-reference="smart_invest-tab"
  >
    <HeadingSmall className={styles.twoWaysTabPanelHading}>
      <h3>A smart, simple way to invest</h3>
    </HeadingSmall>
    <div className={styles.imageParagraphsContainer}>
      <Spacing bottom="2x-large">
        <img
          className={styles.twoWaysTabPanelImage}
          src="/static/img/illustration-man-on-couch.svg"
          alt="illustration of a man sitting on a couch reading something"
        />
      </Spacing>
      <Spacing bottom="normal" top="large">
        <Paragraph>
          Our specialist property team will take care of all the hard work so
          you can sit back and watch your portfolio grow.
        </Paragraph>
      </Spacing>
      <Spacing bottom="normal">
        <Paragraph>
          Simply start by choosing how much you’d like to invest.
        </Paragraph>
      </Spacing>
      <div className={homeStyles.clearFix} />
    </div>
    <BodyText textSize="small">
      <ul className={styles.tickedBulletList}>
        <li className={styles.tickedBulletListItem}>
          Properties are chosen based on their growth potential
        </li>
        <li className={styles.tickedBulletListItem}>
          Our aim is to generate returns that outperform the Australian property
          market. We measure this annually against the CoreLogic Home Value
          Index
        </li>
        <li className={styles.tickedBulletListItem}>
          Choose how much you’d like to invest monthly via direct debit
        </li>
        <li className={styles.tickedBulletListItem}>
          Ability to skip a month or cancel
        </li>
        <li className={styles.tickedBulletListItem}>
          Receive regular updates on how your portfolio is performing
        </li>
      </ul>
    </BodyText>
    {extraInfo}
    <div className={styles.twoWaysTabPanelCTAButtons}>
      {cloneElement(primaryCta, {
        className: styles.primaryCta,
      })}
      <Spacing bottom="normal">{secondaryCta}</Spacing>
    </div>
  </div>
);

const BuildMyOwnPanel = ({
  className,
  primaryCta = <GetStartedButton testRef="bmo-tab-primary-cta" />,
  secondaryCta,
  extraInfo,
}) => (
  <div
    className={classNames(
      className,
      styles.buildMyOwnPanel,
      styles.twoWaysTabPanel
    )}
    data-test-reference="build_your_own-tab"
  >
    <HeadingSmall className={styles.twoWaysTabPanelHading}>
      <h3>Your portfolio, your way</h3>
    </HeadingSmall>
    <div className={styles.imageParagraphsContainer}>
      <Spacing bottom="2x-large">
        <img
          className={classNames(
            styles.twoWaysTabPanelImage,
            styles.buildMyOwnPanel__tabPanelImage
          )}
          src="/static/img/illustration-man-pointing-to-a-house-image-in-poster.svg"
          alt="illustration of a man pointing toward an illustration of a house"
        />
      </Spacing>
      <Spacing bottom="normal" top="large">
        <Paragraph>
          Ideal if you’d like to be in control of which properties, mortgages or
          financial assets to invest in based on your own investment goals and
          are comfortable making those decisions.
        </Paragraph>
      </Spacing>
      <div className={homeStyles.clearFix} />
    </div>
    <BodyText textSize="small">
      <ul className={styles.tickedBulletList}>
        <li className={styles.tickedBulletListItem}>
          Choose which investments to buy Bricks in
        </li>
        <li className={styles.tickedBulletListItem}>
          Decide how much you invest and when
        </li>
        <li className={styles.tickedBulletListItem}>
          Deposit funds and invest today
        </li>
        <li className={styles.tickedBulletListItem}>
          Diversify your portfolio across different properties, mortgages or
          property related financial assets based on your investment goals
        </li>
        <li className={styles.tickedBulletListItem}>
          Receive regular updates on how your portfolio is performing
        </li>
      </ul>
    </BodyText>
    {extraInfo}
    <div className={styles.twoWaysTabPanelCTAButtons}>
      {cloneElement(primaryCta, {
        className: styles.primaryCta,
      })}
      <Spacing bottom="normal">{secondaryCta}</Spacing>
    </div>
  </div>
);

const regularSubtitle = (
  <div data-test-reference="regular-two-ways-to-invest-subtitle">
    Simple fee of {transactionFeeText()}
    <br /> when Bricks are bought and sold
  </div>
);

const TwoWaysToInvest = ({
  smartInvestPrimaryCta,
  smartInvestSecondaryCta,
  buildMyOwnPrimaryCta,
  buildMyOwnSecondaryCta,
  smartInvestExtraInfo,
  buildMyOwnExtraInfo,
  startFromPanel,
  onTabChange,
  scrollToId,
  className,
}) => {
  return (
    <ColoredAngularBackgroundSection
      title="Two ways to invest"
      subtitle={regularSubtitle}
      {...{ scrollToId, className }}
    >
      <Tabs
        centeredLabels
        className={styles.twoWaysTabs}
        testRef="homepage-tabs-control"
        startFromPanel={startFromPanel}
        onTabChange={onTabChange}
        panels={[
          {
            label: 'Smart Invest',
            panel: (
              <SmartInvestPanel
                primaryCta={smartInvestPrimaryCta}
                secondaryCta={smartInvestSecondaryCta}
                extraInfo={smartInvestExtraInfo}
              />
            ),
            labelTestRef: 'smart-invest-label',
          },
          {
            label: 'Build My Own',
            panel: (
              <BuildMyOwnPanel
                primaryCta={buildMyOwnPrimaryCta}
                secondaryCta={buildMyOwnSecondaryCta}
                extraInfo={buildMyOwnExtraInfo}
              />
            ),
            labelTestRef: 'build-your-own-label',
          },
        ]}
      />
    </ColoredAngularBackgroundSection>
  );
};

export default TwoWaysToInvest;
