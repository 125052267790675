import { connect } from 'react-redux';
import { ExtendedHowItWorksSection } from 'src/components/landingPages/common/howItWorksSection/HowItWorksSection';
import { accountSelectorV2 } from 'scripts/redux/selectors/account';
import { fetchAccountInfoV2 as fetchAccountInfoV2Action } from 'scripts/redux/actions/account';
import {
  getSmartInvestPlan,
  planIsActive,
  planWasNeverSignedUpTo,
} from 'src/utils/account';
import { howItWorksVideoId } from 'src/utils/videoIds';
import { isLoggedIn } from 'src/utils/user';
import {
  onboardingProductSelectionUrl,
  settingsEnableUrl,
  accountUrl,
  signupUrl,
  howItWorksUrl,
} from 'src/utils/pageUrls';
import { scrollToId } from 'src/browser/window';
import { userSelector } from 'scripts/redux/selectors/user';
import GetStartedButton from 'src/components/landingPages/common/getStartedButton/GetStartedButton';
import HeadingVideoSection from 'src/components/landingPages/common/headingVideoSection/HeadingVideoSection';
import HowDoIExitSection from 'src/components/landingPages/howItWorks/howDoIExitSection/HowDoIExitSection';
import HowDoIMakeMoneySecondaryCtaContent from 'src/components/landingPages/common/howDoIMakeMoneySection/SecondaryCtaContent';
import HowDoIMakeMoneySection from 'src/components/landingPages/common/howDoIMakeMoneySection/HowDoIMakeMoneySection';
import HowItWorksMeta from 'src/components/meta/HowItWorksMeta';
import Paragraph from 'src/design/components/bodyText/Paragraph';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import SubFooter from 'src/components/subFooter/SubFooter';
import TwoWaysExpandableHowItWorksSteps from 'src/components/landingPages/howItWorks/twoWaysToInvestSection/TwoWaysExpandableHowItWorksSteps';
import TwoWaysSecondaryCta from 'src/components/landingPages/howItWorks/twoWaysToInvestSection/TwoWaysSecondaryCta';
import TwoWaysToInvest from 'src/components/landingPages/common/twoWaysToInvest/TwoWaysToInvest';
import history from 'src/browser/history';
import styles from 'src/components/landingPages/howItWorks/HowItWorks.mscss';
import withState from 'src/decorators/withState';

export const twoWaysAnchor = 'two-ways';
export const howItWorksAnchor = 'how-it-works';

const PrimaryCtaInsideTab = ({ text, ...props }) => (
  <PrimaryButton color={PrimaryButton.colors.SECONDARY} {...props}>
    {text}
  </PrimaryButton>
);

function pickSmartInvestPrimaryCta(account) {
  const testRef = 'si-tab-primary-cta';
  let cta = <GetStartedButton testRef={testRef} />;
  if (account) {
    const siPlan = getSmartInvestPlan(account);
    const siNotCurrentlyActive = !planIsActive(siPlan);
    if (siNotCurrentlyActive) {
      cta = planWasNeverSignedUpTo(siPlan) ? (
        <PrimaryCtaInsideTab
          link={onboardingProductSelectionUrl()}
          text="Set up Smart Invest"
          testRef={testRef}
        />
      ) : (
        <PrimaryCtaInsideTab
          link={settingsEnableUrl()}
          text="Enable Smart Invest"
          testRef={testRef}
        />
      );
    }
  }
  return cta;
}

function pickBuildMyOwnPrimaryCta(user) {
  const testRef = 'bmo-tab-primary-cta';
  return isLoggedIn(user) ? (
    <PrimaryCtaInsideTab
      link={accountUrl()}
      text="View Dashboard"
      testRef={testRef}
    />
  ) : (
    <PrimaryCtaInsideTab
      link={signupUrl()}
      text="Sign Up Now"
      testRef={testRef}
    />
  );
}

const HowItWorks = ({ location, user, account }) => {
  const startFromPanel =
    location.query && location.query.activeTabId
      ? parseInt(location.query.activeTabId, 10)
      : 0;

  const onTabChange = ({ index }) => {
    const { pathname, query } = location;
    history.push({
      pathname,
      query: { ...query, activeTabId: index },
      state: { fromTabClick: true },
    });
  };

  return (
    <div data-test-reference="how-it-works">
      {location.pathname === howItWorksUrl() ? <HowItWorksMeta /> : null}

      <HeadingVideoSection
        videoId={howItWorksVideoId}
        title="How investing with BrickX works"
        captionContent={
          <Paragraph>
            A smart, affordable way to invest in the property market.
          </Paragraph>
        }
      />

      <ExtendedHowItWorksSection
        backgroundColor="lightgrey"
        scrollToId={howItWorksAnchor}
      />

      <TwoWaysToInvest
        scrollToId={twoWaysAnchor}
        onTabChange={onTabChange}
        startFromPanel={startFromPanel}
        smartInvestPrimaryCta={pickSmartInvestPrimaryCta(account)}
        buildMyOwnPrimaryCta={pickBuildMyOwnPrimaryCta(user)}
        smartInvestSecondaryCta={<TwoWaysSecondaryCta />}
        buildMyOwnSecondaryCta={<TwoWaysSecondaryCta />}
        smartInvestExtraInfo={
          <TwoWaysExpandableHowItWorksSteps
            title="How Smart Invest works"
            items={[
              'Our specialist property team carefully select a range of high growth potential properties',
              'Set a monthly amount and Smart Invest buys and reinvests any distributions in Bricks that we expect to outperform the Australian residential property market. We measure this annually against the CoreLogic Home Value Index',
              "We'll take care of everything else and you'll receive your cut of the rent, after expenses, automatically reinvested",
              'As property prices change so does the value of your Bricks',
              'When the time comes, you can put your Bricks up for sale',
            ]}
          />
        }
        buildMyOwnExtraInfo={
          <TwoWaysExpandableHowItWorksSteps
            title="How Build My Own works"
            items={[
              "Our specialist investment team sources properties, mortgages and property related financial assets then breaks them into 'Bricks' for you to buy and hold",
              'You choose which investments to buy Bricks in, based on your investment goals',
              "We'll take care of the investment management and you'll receive your cut of the rent, interest or income after expenses",
              'As asset prices change so does the value of your Bricks',
              'When the time comes, you can put your Bricks up for sale',
            ]}
          />
        }
      />

      <HowDoIMakeMoneySection
        secondaryContent={<HowDoIMakeMoneySecondaryCtaContent />}
      />
      <HowDoIExitSection darkBackground />
      <div className={styles.whiteSpaceAboveFooter}></div>

      <SubFooter />
    </div>
  );
};

export default connect(
  (state) => ({
    ...userSelector(state),
    ...accountSelectorV2(state),
  }),
  {
    fetchAccountInfo: fetchAccountInfoV2Action,
  }
)(
  withState({
    Component: HowItWorks,
    componentDidMount: ({ location, fetchAccountInfo }) => {
      location.query.activeTabId && scrollToId(twoWaysAnchor);
      fetchAccountInfo();
    },
    componentWillReceiveProps: (props, nextProps) =>
      nextProps.location.query.activeTabId &&
      !(nextProps.location.state && nextProps.location.state.fromTabClick) &&
      scrollToId(twoWaysAnchor),
  })
);
