import { Fragment } from 'react';
import styled from 'styled-components';
import { HeroSectionHeading } from 'src/design/styleguide/Headings';
import { breakpointSmall } from 'src/design/styleguide/common/breakpoints';
import AboutMeta from 'src/components/meta/AboutMeta';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import Paragraph from 'src/design/styleguide/Paragraph';
import SectionHeading from 'src/components/landingPages/howItWorks/common/sectionHeading/SectionHeading';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Timeline from 'src/components/aboutUs/Timeline';
import { minimumDeposit } from 'src/settings/funds.js';

const BrSmall = styled.br`
  display: none;

  ${breakpointSmall(`
    display: inline;
  `)}
`;

const AboutUs = () => (
  <Fragment>
    <AboutMeta />
    <ContentBlock
      topPadding="large"
      bottomPadding="large"
      backgroundImage="/static/img/photo-wall-1920.jpg"
      overlayOpacity="0.85"
    >
      <ContentContainer size="small">
        <HeroSectionHeading>ABOUT US</HeroSectionHeading>

        <Spacing top="normal">
          <SectionHeading>What is BrickX?</SectionHeading>
        </Spacing>

        <Spacing top="2x-large">
          <Paragraph align="center">
            BrickX is a fractional property investment platform with the mission
            <BrSmall /> to make property accessible for all Australians.
          </Paragraph>
        </Spacing>
      </ContentContainer>
    </ContentBlock>

    <ContentBlock topPadding="large" bottomPadding="large">
      <ContentContainer size="small">
        <Spacing bottom="normal">
          <SectionHeading>Our Story</SectionHeading>
        </Spacing>

        <Paragraph align="center">
          The dream of owning a home continues to be a dream for many. That’s
          why we created BrickX. We enable you to invest in the property market
          across a range of property related investments with as little as $
          {minimumDeposit} in as fast as a few minutes from signup to purchase.
        </Paragraph>

        <Paragraph align="center">
          To date, we have helped to make property possible for thousands of
          Australians and are eager to continue this journey!
        </Paragraph>

        <Spacing top="large">
          <Timeline
            items={[
              { date: 'June, 2014', text: 'An idea is born' },
              { date: 'December, 2014', text: 'First Property is purchased' },
              {
                date: 'March, 2015',
                text: 'BrickX launches to wholesale investors and first brick is purchased',
              },
              {
                date: 'September, 2016',
                text: 'BrickX launches to all Australians',
              },
              {
                date: 'May, 2017',
                text: 'SQM Research gives us 4-star rating',
              },
              {
                date: 'December, 2017',
                text: '$9M equity investment is raised from NAB and Reinventure',
              },
              { date: 'April, 2018', text: 'Reached 50,000 subscribers' },
              { date: 'August, 2018', text: 'Smart Invest launches' },
              {
                date: 'January, 2019',
                text: 'BrickX Financial Services is granted AFSL to become Responsible Entity',
              },
              {
                date: 'February, 2019',
                text: '$20M of Assets under management',
              },
              { date: 'June, 2019', text: '15,000 members strong' },
              {
                date: 'December 2019',
                text: 'Thundering Herd acquires BrickX',
              },
              {
                date: 'October 2020',
                text: 'Introduced Display Home Purchase and Leaseback transactions',
              },
              {
                date: 'July 2021',
                text: 'First Commercial Property Trust syndicated',
              },
              {
                date: 'October 2021',
                text: 'First Multi-Asset BrickX Trusts syndicated',
              },
              { date: 'November 2021', text: 'Launched BrickX Minors account' },
              {
                date: 'November 2021',
                text: '$30M of Assets under Management',
              },
              {
                date: 'October 2022',
                text: 'First SDA housing Trust syndicated',
              },
              { date: 'January, 2023', text: 'Reached 80,000 subscribers' },
              {
                date: 'August 2023',
                text: 'Introduced 3rd Party Fund Managers',
              },
              {
                date: 'June 2024',
                text: 'First short stay accommodation syndication completed',
              },
              {
                date: 'November 2024',
                text: 'ASIC approval for Debt/Mortgage Investments',
              },
              {
                date: 'November 2024',
                text: 'ASIC approval for Financial Asset (Fund) Investments',
              },
              { date: 'Today', text: '53.8M of Assets under Management' },
            ]}
          />
        </Spacing>
      </ContentContainer>
    </ContentBlock>
  </Fragment>
);

export default AboutUs;
