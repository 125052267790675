import PropTypes from 'prop-types';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';
import FinancialsBox from 'scripts/components/property/FinancialsBox';
import { property as propertyPropType } from 'scripts/constants/PropTypes';
import {
  percentDecimal,
  date,
  dollar,
  dollarDecimal,
  month,
  nextMonthYear,
} from 'scripts/utilities/formatters';
import Markdown from 'react-remarkable';
import Constants from 'scripts/constants/Constants';
import _ from 'lodash';
import { renderCode } from 'src/settings/properties';

// const ADIKeyMetrics = () => {
//   return (
//     <Fragment>
//       <FinancialsPanel
//         className="panel-financials-transparent-header"
//         title="Development Phase: Key Metrics"
//         titleIcon="icn-coins"
//       >
//         <div className="row">
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'COMPLETE VALUE'}
//             value={'$?'}
//             description={'Forecasted'}
//           />
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'CAPITAL COSTS & OUTGOINGS'}
//             value={'$?'}
//             description={'Total Value - Forecasted'}
//           />
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'DEVELOPER UPLIFT'}
//             value={'$?'}
//             description={'Year 1 - Forecasted'}
//           />
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'DEVELOPER UPLIFT'}
//             value={'$?'}
//             description={'Annualised - Forecasted'}
//           />
//         </div>
//       </FinancialsPanel>
//       <FinancialsPanel
//         className="panel-financials-transparent-header"
//         title="Post-Development Phase: Key Metrics"
//         titleIcon="icn-coins"
//       >
//         <div className="row financials-inner-row">
//           <div className="col-md-12 financials-box-subtitle">
//             Project 1: To be determined...
//           </div>
//         </div>
//         <div className="row financials-inner-row">
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'SOMETHING'}
//             value={'$?'}
//             description={'Forecasted'}
//           />
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'SOMETHING'}
//             value={'$?'}
//             description={'Forecasted'}
//           />
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'SOMETHING'}
//             value={'$?'}
//             description={'Forecasted'}
//           />
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'SOMETHING'}
//             value={'$?'}
//             description={'Forecasted'}
//           />
//         </div>
//         <div className="row financials-inner-row">
//           <div className="col-md-12 financials-box-subtitle">
//             Project 2: To be determined...
//           </div>
//         </div>
//         <div className="row">
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'SOMETHING'}
//             value={'$?'}
//             description={'Forecasted'}
//           />
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'SOMETHING'}
//             value={'$?'}
//             description={'Forecasted'}
//           />
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'SOMETHING'}
//             value={'$?'}
//             description={'Forecasted'}
//           />
//           <FinancialsBox
//             className="col-md-3 financials-box-small"
//             title={'SOMETHING'}
//             value={'$?'}
//             description={'Forecasted'}
//           />
//         </div>
//       </FinancialsPanel>
//     </Fragment>
//   );
// };

const LCFKeyMetrics = () => {
  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title="Monthly Distributions: Key Metrics"
      titleIcon="icn-coins"
    >
      <div className="row">
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'RENT PER WEEK'}
          value={'$8,064'}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'ESTIMATED NET INCOME'}
          value={'-$87,853'}
          description={'Per Year - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'ESTIMATED NET RENTAL YIELD'}
          value={'-1.15%'}
          description={'Annualised - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'EST. GROSS RENTAL YIELD'}
          value={'2.92%'}
          description={'Annualised - Forecasted'}
        />
      </div>
      <div className="row">
        <div className="col-md-12 monthly-update">
          The project is in the development phase. The financial metrics above
          are a forecast view of returns once the project is fully completed &
          tenanted.
        </div>
      </div>
    </FinancialsPanel>
  );
};

const EDPKeyMetrics = () => {
  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title="Monthly Distributions: Key Metrics"
      titleIcon="icn-coins"
    >
      <div className="row">
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'RENT PER WEEK'}
          value={'$27,068.44'}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'ESTIMATED NET INCOME'}
          value={'$606,762'}
          description={'Per Year - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'ESTIMATED NET RENTAL YIELD'}
          value={'11.08%'}
          description={'Annualised - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'EST. GROSS RENTAL YIELD'}
          value={'14.92%'}
          description={'Annualised - Forecasted'}
        />
      </div>
      <div className="row">
        <div className="col-md-12 monthly-update">
          The project is in the development phase. The financial metrics above
          are a forecast view of returns once the project is fully completed &
          tenanted.
        </div>
      </div>
    </FinancialsPanel>
  );
};

const PPDKeyMetrics = () => {
  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title="Monthly Distributions: Key Metrics"
      titleIcon="icn-coins"
    >
      <div className="row">
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'RENT PER WEEK'}
          value={'$1,200'}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'ESTIMATED NET INCOME'}
          value={'$26,982'}
          description={'Per Year - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'ESTIMATED NET RENTAL YIELD'}
          value={'2.32%'}
          description={'Annualised - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'EST. GROSS RENTAL YIELD'}
          value={'5.37%'}
          description={'Annualised - Forecasted'}
        />
      </div>
      <div className="row">
        <div className="col-md-12 monthly-update">
          The project is in the development phase. The financial metrics above
          are a forecast view of returns once the project is fully completed &
          tenanted.
        </div>
      </div>
    </FinancialsPanel>
  );
};

const KeyMetrics = ({
  property,
  isPreMarketOpenProperty,
  monthlyDistributions,
  monthlyUpdates,
}) => {
  const title = 'Monthly Distributions: Key Metrics';

  const rentBoxDescription = property.tenantStatus.tenanted
    ? Constants.CURRENTLY_TENANTED
    : Constants.FORECASTED;
  const rentIncomeBoxDescription = property.tenantStatus.tenanted
    ? Constants.CURRENTLY_TENANTED
    : `${Constants.PER_YEAR} - ${Constants.FORECASTED}`;
  const rentYieldBoxDescription = property.tenantStatus.tenanted
    ? Constants.ANNUALISED
    : `${Constants.ANNUALISED} - ${Constants.FORECASTED}`;
  const noDistributionPaidBoxTitle = 'Est. Gross Rental Yield';
  const noDistributionPaidBoxValue =
    property.financials.grossRentalYield::percentDecimal();
  const noDistributionPaidBoxDescription = property.tenantStatus.tenanted
    ? Constants.ANNUALISED
    : `${Constants.ANNUALISED} - ${Constants.FORECASTED}`;

  const noDistributionPaidText = isPreMarketOpenProperty
    ? `The first distribution on ${renderCode(
        property.propertyCode
      )} is estimated to be paid in ${property.platformSettlementDate::nextMonthYear()}`
    : '';

  const lastDistribution = monthlyDistributions[0];

  // if (property.propertyCode === 'ADI01') {
  //   return <ADIKeyMetrics />;
  // }

  if (property.propertyCode === 'LCF01') {
    return <LCFKeyMetrics />;
  }

  if (property.propertyCode === 'EDP01') {
    return <EDPKeyMetrics />;
  }

  if (property.propertyCode === 'PPD01') {
    return <PPDKeyMetrics />;
  }

  return (
    property.propertyType !== 'development' && (
      <FinancialsPanel
        className="panel-financials-transparent-header"
        title={`${title}`}
        titleIcon="icn-coins"
      >
        <div className="row">
          <FinancialsBox
            className="col-md-3 key-metrics-rent financials-box-small"
            title={'RENT PER WEEK'}
            value={property.financials.weeklyRentalIncome::dollar()}
            description={rentBoxDescription}
          />
          <FinancialsBox
            className="col-md-3 net-income-per-year financials-box-small"
            title={'ESTIMATED NET INCOME'}
            value={property.financials.annualNetRentalIncome::dollar()}
            description={rentIncomeBoxDescription}
          />
          <FinancialsBox
            className="col-md-3 net-rental-yield financials-box-small"
            title={'ESTIMATED NET RENTAL YIELD'}
            value={property.financials.netRentalYield::percentDecimal()}
            description={rentYieldBoxDescription}
          />
          {lastDistribution ? (
            <FinancialsBox
              className="col-md-3 last-distribution-paid financials-box-small"
              title={`${lastDistribution.distributionDate::month()} DISTRIBUTION `}
              value={lastDistribution.amount::dollarDecimal()}
              description={`${
                lastDistribution.amount > 0 ? 'PAID' : 'DATE'
              }: ${lastDistribution.paidDate::date()}`}
            />
          ) : (
            <FinancialsBox
              className="col-md-3 last-distribution-paid financials-box-small"
              title={noDistributionPaidBoxTitle}
              value={noDistributionPaidBoxValue}
              description={noDistributionPaidBoxDescription}
            />
          )}
        </div>
        <div className="row">
          <div className="col-md-12 monthly-update">
            {!_.isEmpty(monthlyUpdates) ? (
              <Markdown source={monthlyUpdates[0].monthlyDistributionInfo} />
            ) : (
              noDistributionPaidText
            )}
          </div>
        </div>
      </FinancialsPanel>
    )
  );
};

KeyMetrics.propTypes = {
  property: propertyPropType.isRequired,
  isPreMarketOpenProperty: PropTypes.bool.isRequired,
};

export default KeyMetrics;
