import PropTypes from 'prop-types';
import classNames from 'classnames';
import { elements } from 'scripts/constants/PropTypes';
import Button from 'src/design/components/button/Button';
import styles from 'src/design/components/button/Button.mscss';

const SelectionButton = ({ className, children, ...otherProps }) => (
  <Button
    className={classNames(styles.selectionButton, className)}
    {...otherProps}
  >
    {children}
  </Button>
);

SelectionButton.propTypes = {
  arrow: PropTypes.bool,
  className: PropTypes.string,
  link: PropTypes.string,
  children: PropTypes.oneOfType([elements, PropTypes.string]),
};

export default SelectionButton;
