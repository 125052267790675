import React, { Fragment } from 'react';
import ContentContainer from 'src/design/styleguide/contentContainer/ContentContainer';
import ContentSection from 'src/design/styleguide/contentSection/ContentSection';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import {
  AccentHeading,
  AccentHeadingBreak,
  AnswerTextDark as AnswerText,
  AnswerTextDarkOList as AnswerTextOList,
  BigSection,
  Faq,
  FaqsContainer,
  HeaderContent,
  HeaderImageContainer,
  HeaderParagraph,
  LandingPageLayout,
  QuestionText,
  SectionHeading,
  SocialLinkIcon,
  SocialLinksContainer,
  SocialLinkShareLabel,
  StaticIconText,
  WhyReasonsContainer2Grid } from 'src/components/landingPages/common/design/PageContent';
import ContentBlock from 'src/design/styleguide/contentBlock/ContentBlock';
import { Heading } from 'src/design/styleguide/Headings';
import Header from 'src/design/components/header/Header';
import { BrickxMinorLogo } from 'src/components/brickxLogo/BrickxLogo';
import { TopLevelMenuItem } from 'src/design/components/header/MenuItemTypes';
import {
  aboutUrl,
  contactUrl,
  pdsUrl
} from 'src/utils/pageUrls';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import PrimaryButton from 'src/design/components/button/PrimaryButton';
import { scrollToId } from 'src/browser/window';
import { menuOpenSelector } from 'src/store/headerMenuReducer';
import {
  toggleMenu as toggleMenuAction,
  closeMenu as closeMenuAction,
} from 'src/store/headerMenuActions';
import { connect } from 'react-redux';
import Video from 'src/design/components/video/Video';
import { minorsVideoId } from 'src/utils/videoIds';
import Footer from 'src/components/footer/Footer';

const BrickXMinorLandingPageHeader = connect(menuOpenSelector, {
  toggleMenu: toggleMenuAction,
  closeMenu: closeMenuAction,
})(({ menuOpen, toggleMenu, closeMenu }) => (
  <Header
    title="Minors"
    logo={<BrickxMinorLogo/>}
    mobileMenuOpen={menuOpen}
    onLogoClick={closeMenu}
    onHamburgerIconClick={toggleMenu}
  >
    <TopLevelMenuItem onClick={() => scrollToId('why-brickx-minors')} text="Why BrickX for Minors?"/>
    <TopLevelMenuItem onClick={() => scrollToId('brickx-minor-faqs')} text="FAQs"/>
    <TopLevelMenuItem link={aboutUrl()} text="About Us" forceExternal/>
  </Header>
));

const BrickXMinorLandingPageBody = () => {
  const pagePublicLink = `https://www.brickx.com/minors`;
  return (
    <Fragment>
      <ContentBlock
        overlayOpacity="0.85"
        backgroundImage="static/img/brickx-minors/BrickX Minors - Banner FA.png">
        <ContentSection as="section">
            <ContentContainer topPadding="large" bottomPadding="x-large">
              <HeaderContent>
                <Spacing top={{ base: '2x-large', breakpointSmall: 'none' }}>
                  <HeaderImageContainer>
                    <div className="videoWrapper">
                      <Video videoId={minorsVideoId} />
                    </div>
                  </HeaderImageContainer>
                </Spacing>
                <Heading align="left">
                  <AccentHeading>
                    Introducing
                    <AccentHeadingBreak /> BrickX for Minors
                  </AccentHeading>
                  <br />Get kids on the property ladder sooner!
                </Heading>
                <Spacing top="normal">
                  <HeaderParagraph>
                    BrickX is excited to introduce a new product giving you the opportunity to invest in fractions of property on behalf of Minors under 18 years of age.
                  </HeaderParagraph>
                </Spacing>
                <Spacing bottom="normal">
                  <HeaderParagraph>
                    Build a strong financial foundation for your child, grandchild, niece or nephew, one Brick at a time! <br /><br /> Minor accounts can also invest in fractions of mortgages and other Property related financial investments to help you achieve investment goals sooner! <br /><br />                    See below for <strong>how it works</strong> and to <strong>sign up</strong> for a Minor account.
                  </HeaderParagraph>
                </Spacing>
                <Spacing bottom="normal">
                  <HeaderParagraph>
                    <PrimaryButton
                      link={'/logout-signup-minor'}
                      color={PrimaryButton.colors.SECONDARY}
                      size={PrimaryButton.sizes.NORMAL}>
                      Sign Up Now
                    </PrimaryButton>
                  </HeaderParagraph>
                </Spacing>
                <Spacing bottom="normal">
                  <SocialLinksContainer >
                    <SocialLinkShareLabel><strong>Share On: </strong></SocialLinkShareLabel>
                    <SocialLinkIcon link={pagePublicLink} social="facebook" />
                    <SocialLinkIcon link={pagePublicLink} social="linkedin" />
                    <SocialLinkIcon link={pagePublicLink} social="twitter" />
                    <SocialLinkIcon link={pagePublicLink} social="whatsapp" />
                    <SocialLinkIcon link={pagePublicLink} social="email" icon="envelope" />
                  </SocialLinksContainer>
                </Spacing>
              </HeaderContent>
            </ContentContainer>
        </ContentSection>
      </ContentBlock>

      <BigSection
        id="why-brickx-minors"
        as="section"
        topPadding="x-large"
        bottomPadding="x-large"
        backgroundColor="lightgrey"
      >
        <ContentSection>
          <SectionHeading>Why BrickX for Minors?</SectionHeading>
        </ContentSection>
        <WhyReasonsContainer2Grid>
          <StaticIconText size="medium" icon="static/img/brickx-minors/Icon 4.svg" title="Get kids on the property ladder" text="Give the kids a head-start in saving for their own property by accessing current property market returns."/>
          <StaticIconText size="medium" icon="static/img/brickx-minors/Icon 3.svg" title="Saving for a Purpose" text="Start, or continue, the financial education of the kids in your life, through the real-life experience of property investing with relatively small sums of money."/>
          <StaticIconText size="medium" icon="static/img/brickx-minors/Icon 2.svg" title="Making Smart Choices" text="Lots of small amounts add up to large amounts over time.  Helping kids make smart choices with their money early on can foster healthy financial behaviours later in life."/>
          <StaticIconText size="medium" icon="static/img/brickx-minors/Icon 1.svg" title="The gift of Bricks that keeps on giving!" text="Start with as many or as few Bricks as you like. Re-invest income and add more Bricks over time so your gift continues to grow."/>
        </WhyReasonsContainer2Grid>
      </BigSection>

      <BigSection
        id="brickx-minor-faqs"
        width="large"
        style={{
          backgroundColor: '#FFF0C8'
        }}
      >
        <SectionHeading>FAQs</SectionHeading>
        <hr />
        <FaqsContainer>
          <div>
            <Faq
              question={<QuestionText>How do BrickX Minor accounts work?</QuestionText>}
              answer={<div>
                <AnswerText>Children under 18 years of age are not able to invest directly with BrickX. However, with the launch of BrickX Minor accounts, parents, close relatives (ie. aunts, uncles, grandparents) and guardians aged 18 years or over can now invest as trustee for the benefit of a Minor. Hooray!</AnswerText>
                <AnswerText>A Minor account will function the same way as an individual BrickX account, however you will hold Bricks as trustee for the Minor and when the Minor turns 18, you may transfer the account holdings into the name of the (now adult) Minor.</AnswerText>
                <AnswerText>There may be tax implications for you to consider when investing on behalf of a Minor. We therefore recommend you speak to a qualified tax advisor prior to investing.</AnswerText>
              </div>}
            />
            <hr />
            <Faq
              question={<QuestionText>What does opening a Minor account involve?</QuestionText>}
              answer={<div>
                <AnswerText>The application process for a Minor account is the same as for an individual account, except you will make the application in your name as trustee to hold the interest on trust for the Minor as beneficiary. You must be 18 years or over and an Australian resident.</AnswerText>
                <AnswerText>In addition to providing your own ID information (name, DOB, residential address, Drivers Licence) , the application process requires you to provide the full name and DOB of the Minor and by submitting the application you are making a declaration of bare trust.</AnswerText>
              </div>}
            />
            <hr />
            <Faq
              question={<QuestionText>How much money do I need to invest to open a Minor Account?</QuestionText>}
              answer={<div>
                <AnswerText>The minimum initial	deposit for a Minor account is $250 (which is the same as for an individual account).</AnswerText>
              </div>}
            />
            <hr />
            <Faq
              question={<QuestionText>Can I top up the Minor account after the initial deposit?</QuestionText>}
              answer={<div>
                <AnswerText>Yes, you can deposit additional funds into a Minor account at any time and at any frequency you wish. </AnswerText>
                <AnswerText>If you choose our Smart Invest option, you can set a monthly Direct Debit amount to automatically credit the account. Alternatively, you can create a recurring BPAY deposit from your external Bank account. </AnswerText>
              </div>}
            />
            <hr />
            <Faq
              question={<QuestionText>How do I sign up for a BrickX Minor Account?</QuestionText>}
              answer={<div>
                <AnswerText>Signing up for a BrickX Minor Account is easy. Simply:</AnswerText>
                <AnswerTextOList
                  items={[
                    <span>Go to <Styledlink href={'/signup'} forceExternal>brickx.com/signup</Styledlink> and select the Minor account type.</span>,
                    'Enter your first name, an email address for the account and choose a password (Note: if you are an existing BrickX member you will need to use a different email address to your existing account to create a Minor Account)',
                    'Click Sign Up and follow the prompts - make sure you have the Minor’s date of birth and your TFN ready!'
                  ]}
                />
              </div>}
            />
          </div>
          <div>
            <Faq
              question={<QuestionText>Can I sign up for more than one Minor account?</QuestionText>}
              answer={<div>
                <AnswerText>Yes! You can sign up for as many Minor accounts as you like provided you are a parent, close relative or guardian of the Minor. Complete the Signup steps previously outlined for each Minor account via brickx.com/signup. </AnswerText>
                <AnswerText>Please note you will need to have a separate email address for each Minor account. </AnswerText>
              </div>}
            />
            <hr />
            <Faq
              question={<QuestionText>Can I sell any Bricks purchased and/or withdraw any funds before the Minor beneficiary turns 18? </QuestionText>}
              answer={<div>
                <AnswerText>Yes, as trustee you can manage the account as you see fit provided you are fulfilling your duties as trustee. However, we recommend you seek independent tax advice to understand any tax implications before doing so. </AnswerText>
              </div>}
            />
            <hr />
            <Faq
              question={<QuestionText>What happens when the Minor reaches age 18?</QuestionText>}
              answer={<div>
                <AnswerText>Once the Minor has reached the age of 18 or older, you may transfer the Account holdings into the name of the (now adult) Minor. It is up to you as trustee when you choose to initiate the Transfer. </AnswerText>
              </div>}
            />
            <hr />
            <Faq
              question={<QuestionText>How does tax work for a Minor account?</QuestionText>}
              answer={<div>
                <AnswerText>If you are investing on behalf of a Minor, you will be taken to be doing so as trustee, and it is your responsibility to obtain your own legal and taxation advice, understand your duties as trustee, and administer the trust accordingly. </AnswerText>
                <AnswerText>You will be liable to pay any taxes on behalf of the Minor in respect of any distributions paid, as well as any capital gains or losses. You will also be responsible for satisfying any tax reporting obligations required of the Minor, e.g. lodgment of an income tax return.</AnswerText>
                <AnswerText>Any future transfers of Bricks to the Minor upon reaching age 18 may also be subject to specific tax implications, including the CGT rules. </AnswerText>
                <AnswerText>See section 11.6 of the <Styledlink href={pdsUrl()}>PDS</Styledlink> for more information on taxation for Minor Accounts. </AnswerText>
              </div>}
            />
            <hr />
            <Faq
              question={<QuestionText>What are the fees? </QuestionText>}
              answer={<div>
                <AnswerText>The normal BrickX fees apply for Minor accounts. For more information on BrickX fees, please refer to section 10 of the <Styledlink href={pdsUrl()}>PDS</Styledlink>. </AnswerText>
              </div>}
            />
            <hr />
            <Faq
              question={<QuestionText> I have more questions! How can I get in touch with BrickX? </QuestionText>}
              answer={<div>
                <AnswerText>You can contact our team with any further questions via the <Styledlink href={contactUrl()} forceExternal>Contact Us</Styledlink> page or by emailing <a href="mailto:info@brickx.com">info@brickx.com</a>. </AnswerText>
              </div>}
            />
          </div>
        </FaqsContainer>
      </BigSection>
    </Fragment>
  );
};

const BrickXMinorLandingPage = () => {
  return (
    <LandingPageLayout>
      <BrickXMinorLandingPageHeader />
      <BrickXMinorLandingPageBody />
      <Footer />
    </LandingPageLayout>);
};

export default BrickXMinorLandingPage;
