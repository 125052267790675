// All trading related configurations
// TODO: migrate all constans from scripts/constants/Trading

import { getTransactionFeeRatio } from './financials';

export const BUY_BROKERAGE_PERCENTAGE = getTransactionFeeRatio();
export const SELL_BROKERAGE_PERCENTAGE = getTransactionFeeRatio();
export const MAXIMUM_PRICE_DEVIATION_PERCENTAGE = 0.1;
export const MAXIMUM_OWNERSHIP_PERCENTAGE = 0.05;
export const LOWEST_SELL_PRICE_PERCENTAGE = 0.8;
export const HIGHEST_SELL_PRICE_PERCENTAGE = 1.2;
export const MAXIMUM_NUM_OF_BRICKS_PER_USER_PER_PROPERTY = 500;
export const TOTAL_BRICKS_PER_PROPERTY = 10000;
export const TRADE_SUCCEED_SOCIAL_SHARE_THRESHOLD = 0.1;
export const PREMIUM_PRICE_FIRST_LIMIT = 0.05;
export const PREMIUM_PRICE_SECOND_LIMIT = 0.1;
export const NUM_OF_MONTHS_FOR_AMORTISING = 60;
export const PROPERTIES_WITH_MAX_BUY_LIMIT_MAPPING = {
  ENM01: 500,
  MOS01: 500,
  PRA01: 500,
  ANN01: 500,
  DBB01: 500,
  BND01: 500,
  PML01: 500,
  DAR01: 500,
  SUR01: 500,
  PPT01: 500,
  MAN01: 500,
  BRW01: 500,
  STP01: 500,
  BLM01: 500,
  DAR02: 500,
  KIN01: 500,
  SLD01: 500,
  BAN01: 500,
  KGT01: 500,
  NML01: 1500,
  CNG01: 1500,
  ARN01: 1500,
  PPD01: 4999,
};
export const VOTE_CLOSED_PROPERTIES = ['BLM01', 'PML01', 'PRA01'];
/* Some data for SOLD PROPERTIES */
export const SOLD_PROPERTIES = {
  BLM01: {
    soldPrice: 2430000,
    finalBrickPrice: 180.38,
  },
  PML01: {
    soldPrice: 1550000,
    finalBrickPrice: 150.58,
  },
  WYN01: {
    soldPrice: 532000,
    finalBrickPrice: 29.69,
  },
  TAR01: {
    soldPrice: 800000,
    finalBrickPrice: 49.77,
  },
  CLN01: {
    soldPrice: 930000,
    finalBrickPrice: 57.24,
  },
  CLN02: {
    soldPrice: 875000,
    finalBrickPrice: 55.58,
  },
  CLN03: {
    soldPrice: 900000,
    finalBrickPrice: 56.35,
  },
};
