import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { financialMetricsSelector } from 'scripts/redux/selectors/financials';
import { fetchFinancialMetrics } from 'scripts/redux/actions/financials';
import { financialsMetrics as financialsMetricsPropType } from 'scripts/constants/PropTypes';
import { dollarAbbreviated, number } from 'scripts/utilities/formatters';

import { HeadingLarge } from 'src/design/components/heading/Headings';
import Paragraph from 'src/design/components/bodyText/Paragraph';

import styles from 'src/components/landingPages/home/FinancialMetricsBar.mscss';

const mapStateToProps = (state) => ({
  ...financialMetricsSelector(state),
});

@connect(mapStateToProps, {
  fetchFinancialMetrics,
})
export default class FinancialMetricsBar extends Component {
  static propTypes = {
    fetchFinancialMetrics: PropTypes.func.isRequired,
    metrics: financialsMetricsPropType,
    className: PropTypes.string,
  };

  componentDidMount() {
    this.props.fetchFinancialMetrics();
  }

  render() {
    if (!this.props.metrics) return null;

    const { members, latestTrustValuesSum } = this.props.metrics;

    return (
      <section
        className={classNames(styles.brickXStatisticsBar, this.props.className)}
      >
        <div className={styles.brickXStatisticsBarInner}>
          <div className={styles.statistic}>
            <HeadingLarge
              className={styles.statisticValue}
              testRef="active-members"
            >
              <h2>{members::number()}</h2>
            </HeadingLarge>
            <Paragraph textSize="small" className={styles.statisticName}>
              Members
            </Paragraph>
          </div>
          <div className={styles.statistic}>
            <HeadingLarge
              className={styles.statisticValue}
              testRef="value-of-all-properties"
            >
              <h2>{latestTrustValuesSum::dollarAbbreviated()}</h2>
            </HeadingLarge>
            <Paragraph textSize="small" className={styles.statisticName}>
              Last value of all BrickX properties
            </Paragraph>
          </div>
        </div>
      </section>
    );
  }
}
