import { Fragment } from 'react';
import Markdown from 'react-remarkable';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { breakpointMedium } from 'src/design/styleguide/common/breakpoints';
import {
  date,
  monthYear,
  dollar,
  brickPrice,
} from 'scripts/utilities/formatters';
import {
  propertyCapitalReturnsBoxHistoricalSuburbGrowthDescription,
  historicalGrowthDataProvider,
  historicalGrowthTitle,
} from 'src/settings/text';
import {
  property as propertyPropType,
  monthlyUpdates as monthlyUpdatesPropType,
} from 'scripts/constants/PropTypes';
import FinancialsBox from 'scripts/components/property/FinancialsBox';
import FinancialsPanel from 'scripts/components/shared/FinancialsPanel';

const StyledFinancialsBox = styled(FinancialsBox)`
  @media (min-width: 980px) {
    margin-bottom: 15px;
  }

  ${breakpointMedium(`
    flex: 1 0 0px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    & .gray-box {
      height: 100%;
    }
  `)}
`;

const KeyMetricsBoxesContainer = styled.div`
  @media (min-width: 980px) {
    display: flex;
    flex-direction: column;
  }

  ${breakpointMedium(`
    flex-direction: row;
    justify-content: space-between;
  `)}
`;

const KPIKeyMetrics = () => {
  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title="Development Phase: Key Metrics"
      titleIcon="icn-coins"
    >
      <div className="row">
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'COMPLETE VALUE'}
          value={'$3,800,000'}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'CAPITAL COSTS & OUTGOINGS'}
          value={'$3,517,843'}
          description={'Total Value - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'DEVELOPER UPLIFT'}
          value={'6.29%'}
          description={'Year 1 - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'DEVELOPER UPLIFT'}
          value={'6.29%'}
          description={'Annualised - Forecasted'}
        />
      </div>
      <div className="row">
        <div className="col-md-12 monthly-update">
          The first distribution on KPI01 is estimated to be paid in Oct 2025
        </div>
      </div>
    </FinancialsPanel>
  );
};

const LCFKeyMetrics = () => {
  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title="Development Phase: Key Metrics"
      titleIcon="icn-coins"
    >
      <div className="row">
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'COMPLETE VALUE'}
          value={'$13,982,500'}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'CAPITAL COSTS & OUTGOINGS'}
          value={'$11,461,825'}
          description={'Total Value - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'DEVELOPER UPLIFT'}
          value={'22%'}
          description={'Year 1 - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'DEVELOPER UPLIFT'}
          value={'22%'}
          description={'Annualised - Forecasted'}
        />
      </div>
    </FinancialsPanel>
  );
};

const EDPKeyMetrics = () => {
  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title="Development Phase: Key Metrics"
      titleIcon="icn-coins"
    >
      <div className="row">
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'COMPLETE VALUE'}
          value={'$26,538,690'}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'CAPITAL COSTS & OUTGOINGS'}
          value={'$23,486,761'}
          description={'Total Value - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'DEVELOPER UPLIFT'}
          value={'13%'}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'DEVELOPER UPLIFT'}
          value={'5.2%'}
          description={'Annualised - Forecasted'}
        />
      </div>
    </FinancialsPanel>
  );
};

const PPDKeyMetrics = () => {
  return (
    <FinancialsPanel
      className="panel-financials-transparent-header"
      title="Development Phase: Key Metrics"
      titleIcon="icn-coins"
    >
      <div className="row">
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'COMPLETE VALUE'}
          value={'$2,800,000'}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'CAPITAL COSTS & OUTGOINGS'}
          value={'$2,325,507'}
          description={'Total Value - Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'DEVELOPER UPLIFT'}
          value={'20%'}
          description={'Forecasted'}
        />
        <FinancialsBox
          className="col-md-3 financials-box-small"
          title={'DEVELOPER UPLIFT'}
          value={'20%'}
          description={'Annualised - Forecasted'}
        />
      </div>
    </FinancialsPanel>
  );
};

const KeyMetrics = ({
  property,
  isPreorderOrComingSoonProperty,
  monthlyUpdates,
  averageAnnualGrowthAmount,
  averageAnnualGrowthNumberOfYears,
}) => {
  const isPreOrderOrNotIndependentValuedProperty =
    isPreorderOrComingSoonProperty || !property.financials.isIndependentValued;

  const title = 'Capital Returns: Key Metrics';
  const latestValueBoxTitle = isPreOrderOrNotIndependentValuedProperty
    ? 'Total Purchase Cost'
    : 'Latest Value';
  const latestValueBoxDescription = isPreOrderOrNotIndependentValuedProperty
    ? property.platformSettlementDate::date()
    : property.financials.latestValuationDate::monthYear();

  const brickValueBoxTitle = isPreOrderOrNotIndependentValuedProperty
    ? 'INITIAL BRICK PRICE'
    : 'BRICK VALUE';
  const brickValueBoxDescription = isPreOrderOrNotIndependentValuedProperty
    ? property.platformSettlementDate::date()
    : property.financials.latestValuationDate::monthYear();

  const debtBoxDescription = isPreOrderOrNotIndependentValuedProperty
    ? property.platformSettlementDate::date()
    : property.financials.latestValuationDate::monthYear();

  return (
    <Fragment>
      {property.propertyCode === 'KPI01' && <KPIKeyMetrics />}
      {property.propertyCode === 'LCF01' && <LCFKeyMetrics />}
      {property.propertyCode === 'EDP01' && <EDPKeyMetrics />}
      {property.propertyCode === 'PPD01' && <PPDKeyMetrics />}
      <FinancialsPanel
        className="panel-financials-transparent-header panel-financials-capital-returns"
        title={title}
        titleIcon="icn-line-chart-blue"
      >
        <KeyMetricsBoxesContainer>
          <StyledFinancialsBox
            className="five-year-historical-suburb-growth financials-box-small"
            title={historicalGrowthTitle(property.propertyType)}
            value={averageAnnualGrowthAmount}
            description={propertyCapitalReturnsBoxHistoricalSuburbGrowthDescription(
              averageAnnualGrowthNumberOfYears,
              historicalGrowthDataProvider(property.propertyType)
            )}
            valueTestRef="capital-returns-value"
            descriptionTestRef="capital-returns-description"
          />
          <StyledFinancialsBox
            className="financials-box-small latest-value"
            title={latestValueBoxTitle}
            value={property.financials.latestValue::dollar()}
            description={latestValueBoxDescription}
          />
          <StyledFinancialsBox
            className="financials-box-small outstanding-debt"
            title={`DEBT`}
            value={
              property.financials.debtTerms
                ? property.financials.debtTerms.outstandingDebt::dollar()
                : '$0'
            }
            description={debtBoxDescription}
          />
          <StyledFinancialsBox
            className="financials-box-small brick-value"
            title={brickValueBoxTitle}
            value={property.financials.brickValue::brickPrice()}
            description={brickValueBoxDescription}
          />
        </KeyMetricsBoxesContainer>
        <div className="row">
          <div className="col-md-12 monthly-update">
            {monthlyUpdates.length ? (
              <Markdown source={monthlyUpdates[0].brickValuationInfo} />
            ) : (
              ''
            )}
          </div>
        </div>
      </FinancialsPanel>
    </Fragment>
  );
};

KeyMetrics.propTypes = {
  property: propertyPropType.isRequired,
  isPreorderOrComingSoonProperty: PropTypes.bool.isRequired,
  monthlyUpdates: monthlyUpdatesPropType,
  averageAnnualGrowthAmount: PropTypes.string.isRequired,
};

export default KeyMetrics;
